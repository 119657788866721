import React from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import ReactWOW from "react-wow"
import PageBanner from "../components/Common/PageBanner"
import Footer from "../components/App/Footer"
import ausimap from "../assets/images/about/graphic-australia-locations.svg"
import WhyChoose from "../components/AboutUs/WhyChoose"
import CoreValues from "../components/AboutUs/CoreValues"
import Achievements from "../components/AboutUs/Achievements"
import PathToOwnership from "../components/AboutUs/PathToOwnership"
import CTABanner from "../components/Common/CTABanner"
import SEO from "../components/App/SEO"
import UnsubscribeSuccess from "../assets/images/unsubscribe-success.svg"
import UnsubscribeCancel from "../assets/images/unsubscribe-cancel.svg"

import ImgAboutUs from "../assets/images/about_us.png"

const AboutUs = () => {
  return (
    <Layout>
      <SEO
        title="About Oxygen | Oxygen - Home Loans Made Simple"
        description="At Oxygen we have a very simple value proposition. Provide our customers with a higher quality of advice, the most competitive loan, and do it hassle free."
      />
      <Navbar />
      <div className="home-hero-banner mb-md-5 unsubscribe-page">
          <div className="container hero mt-5 mb-5">
            <div className="row align-items-center justify-content-center">
              <div className="col-12 col-md-10 col-lg-10">
                <div className="main-banner-content">
                  <h1 className="text-center">
                    Thanks for staying<br></br>with us!
                  </h1>
                  <h1 className="text-center">
                    Unsubscribed<br></br>Successfully!
                  </h1>
                  <ReactWOW delay=".2s" animation="fadeInDown">
                    <div className="main-banner-image mt-4 mt-lg-5">
                      <img loading="eager" src={UnsubscribeSuccess} alt="banner" />
                      <img loading="eager" src={UnsubscribeCancel} alt="banner" />
                    </div>
                  </ReactWOW>
                  
                  <h2 className="text-center mt-4 mt-lg-5">You will no longer receive email notifications.</h2>   
                  <h2 className="text-center mt-4 mt-lg-5">You will continue receiving our email notifications.</h2>                  
                </div>
              </div>

              
            </div>
          </div>
      </div>
      <Footer />
    </Layout>
  )
}

export default AboutUs
